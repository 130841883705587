<template>
  <div id="content" :style="backgroundDiv">
    <NavbarCommon :title="title"></NavbarCommon>
    <Clockin></Clockin>
  </div>
</template>

<script>
import Clockin from '@/components/clockin/Clockin'
import NavbarCommon from '@/components/common/NavbarCommon'
export default {
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  components: { Clockin, NavbarCommon },
  data () {
    return {
      title: '打卡功课列表',
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/life.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  mounted () {
  },
  methods: {
    // joinclass () {
    //   this.$router.push('/joinclass')
    // }
  }

}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
.vancell {
  //   border-top: 0.278vw #ccc solid;
  background-color: rgba(255, 255, 255, 0.959);
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
</style>
