<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      right-text="首页"
      class="navbar-top"
      @click-right="onClickRight"
    >
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  props: ['title', 'back'],
  methods: {
    onClickLeft () {
      console.log(this.back)
      if (this.back === 'buddhism') { this.$router.push('/buddhism') } else if (this.back === 'corse') { this.$router.push('/course') } else if (this.back === 'Testinfo') { this.$router.push('/Testinfo') } else if (this.back === 'pointinfo') { this.$router.push('/pointinfo') } else this.$router.go(-1)
    },
    onClickRight () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-top {
  background-color: rgba(50, 10, 0, 1);
}
::v-deep .van-nav-bar .van-icon {
  color: white;
}
::v-deep .van-ellipsis {
  color: white;
}
::v-deep .van-nav-bar__text {
  color: white;
}
</style>
