<template>
  <div>
    <!-- <div class="title">功课列表</div> -->
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <p>刷新次数: {{ count }}</p>
    </van-pull-refresh> -->

    <ul
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      v-if="clockLength"
    >
      <li
        v-for="clockincourses in $store.state.clockin.clockinList"
        :key="clockincourses.id"
        @click="toClockin(clockincourses.id, clockincourses.title)"
      >
        <div v-html="clockincourses.title"></div>
        <div class="flex-container">
          <div class="flex-item">
            <span v-if="clockincourses.clockpro" class="clockin"
              >您已打卡 {{ clockincourses.clockpro }} 天</span
            >
            <span v-else class="noclockin">本课程您还没有开始打卡</span>
          </div>
          <div class="flex-item">
            <span v-if="clockincourses.today" class="hasClockin"
              >今天已打卡</span
            >
            <span v-else class="unClockin">今天未打卡</span>
          </div>
        </div>
      </li>
    </ul>
    <van-loading v-else size="6.667vw" vertical>功课列表加载中...</van-loading>
    <!-- <div class="marginbottom"></div> -->
  </div>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      finished: false,
      isLoading: false
    }
  },
  components: {
    // NavbarCommon
  },
  mounted () {
    // if (this.$store.state.clockin.clockinList.length === 0) {
    this.$store.dispatch('getClockinListAction', {
      uid: localStorage.getItem('uid')
    })
    // }
  },
  computed: {
    clockLength () {
      if (this.$store.state.clockin.clockinList.length > 0) {
        return true
      } else {
        return false
      }
    },
    clockinList () {
      let clockinList = this.$store.state.clockin.clockinList
      //   console.log(uidPaperInfo, ' uidPaperInfo   uidPaperInfo')
      if (clockinList.length > 0) {
        return clockinList
      } else {
        clockinList = localStorage.getItem('clockinList')
      }
      //   const uidPaperInfo = this.$store.state.study.uidPaperInfo || localStorage.getItem('uidPaperInfo')
      //   console.log(uidPaperInfo, 'ipppp')
      if (clockinList === '') { clockinList = null }
      var clockinListArr = []
      if (clockinList !== null) {
        clockinListArr = JSON.parse(clockinList)
      }
      return clockinListArr
    }
  },
  methods: {
    // onRefresh () {
    //   setTimeout(() => {
    //     this.$msg('刷新')
    //     this.isLoading = false
    //     this.count++
    //   }, 1000)
    // },
    toClockin (id, name) {
      localStorage.setItem('clockid', id)
      localStorage.setItem('clockinname', name)
      this.$router.push('/clockin')
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
}
</script>

<style lang='scss' scoped>
.title {
  color: white;
  background-color: rgb(70, 27, 27);
  line-height: 5.556vw;
  font-size: 4.167vw;
  height: 9.722vw;
  margin: 0 auto;
  padding-top: 4.167vw;
  text-align: center;
}

ul,
li {
  padding: 0vw;
  margin: 0vw;
  list-style: none;
}
ul {
  background-color: rgb(241, 231, 231);
  text-align: left;
  li {
    // height: 5.556vw;
    padding: 1.389vw;
    color: rgb(37, 26, 26);
    border-bottom: 0.278vw solid rgb(14, 12, 12);
    font-size: 3.611vw;
    line-height: 5.556vw;
  }
}
.flex-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.flex-item {
  width: 50%;
  text-align: center;
}
.clockin {
  color: rgb(196, 145, 111);
  padding: 0.556vw;
  margin-right: 4.167vw;
  margin-top: 1.389vw;
}
.noclockin {
  color: red;
  font-weight: bold;
  font-size: 3.333vw;
  margin-right: 2.778vw;
  margin-top: 1.389vw;
}
.hasClockin {
  color: green;
}
.unClockin {
  color: red;
}
.marginbottom {
  height: 14.444vw;
}
</style>
